import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {PortfolioHttpService} from '../../../services/http-services/portfolio/portfolio-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChartTitles} from '../../../enums/chart-enums';
import {WxClientPortfolio, WxPortfolioData} from '../../../interfaces/portfolio-interfaces';
import {PortfolioStatus} from '../../../enums/portfolio-enums';
import {fadeInOutAnimation} from '../../../animations/FadeInOutAnimation';
import {GeneralHelperService} from '../../../services/helpers/general-helper';
import {ChartHelperService} from '../../../services/helpers/chart-helper.service';
import {PortfolioHelperService} from '../../../services/helpers/portfolio-helper';
import {formatDate} from '@angular/common';
import {BaseCurrencyItems, EmailRegex} from '../../../constants/general-variables';
import {UiHelperService} from '../../../services/helpers/ui-helper.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-single-portfolio',
  templateUrl: './single-portfolio.component.html',
  animations: [fadeInOutAnimation]
})
export class SinglePortfolioComponent implements OnInit {
  isPortfolioAssetsShow = false;
  isAssetsPriceRequestFinished = false;
  singlePortfolioData!: WxClientPortfolio;
  portfolioId!: number;
  portfolioData!: WxPortfolioData;
  fmpCompanyData: any;
  formattedMonthlyPerformanceData: any;
  isUploadModalShow = false;
  allCompaniesFmpPrices: any;
  formattedCurrencyExposureData: any;
  formattedAssetAllocationData: any;
  formattedAllocationBySectorData: any;
  formattedCountryExposureData: any;
  responsePortfolio: any;
  initialDate = this.generalHelperService.returnFormattedDate(Date.now());
  selectedDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  isInitialDate = true;
  editPortfolioName = false;
  showDateWarning = false;
  activeTicker = '';
  activeCompanyPortfolioData: any;
  totalPortfoliosValuation = 0;
  initialFilteredAssets: any[] = [];
  filteredAssets: any[] = [];
  news: any[] = [];
  filterItems: string[] = [];
  currencyValues: any;
  isBaseCurrencyDropdownOpen = false;
  selectedBaseCurrency = 'USD';
  portfolioDataYtd = '';
  @ViewChild('dropdownCurrencyBtn') dropdownCurrencyBtn: ElementRef | undefined;
  portfolioForm!: FormGroup;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly ChartTitles = ChartTitles;
  protected readonly PortfolioStatus = PortfolioStatus;
  protected readonly BaseCurrencyItems = BaseCurrencyItems;

  constructor(private portfolioHttpService: PortfolioHttpService,
              private generalHelperService: GeneralHelperService,
              private chartHelperService: ChartHelperService,
              private activatedRoute: ActivatedRoute,
              private portfolioHelperService: PortfolioHelperService,
              private renderer: Renderer2,
              private router: Router,
              private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private uiHelperService: UiHelperService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      this.portfolioId = res.id;
      this.singlePortfolioData = {...history.state.data};
      if (this.singlePortfolioData.status === PortfolioStatus.completed) {
        this.getLatestCurrencyExchangeRates();
        this.buildForm();
      }
    });
    this.listenToClickDropdown();
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownCurrencyBtn, this.dropdownCurrencyBtn, e)) {
        this.isBaseCurrencyDropdownOpen = false;
      }
    });
  }

  getSinglePortfolioData(): void {
    this.portfolioHttpService.getPortfolioStatementData({id: this.portfolioId}).then(res => {
      this.responsePortfolio = res.data;

      this.initialDate = this.generalHelperService.returnFormattedDate(this.responsePortfolio.portfolio_created_at);
      this.formattedPortfolioDataNew(this.responsePortfolio);
      if (Object.keys(this.portfolioData.fmp).length) {
        this.formatterAllocationBySectorData(Object.values(this.portfolioData.fmp));
        this.formatterCountryExposureData(Object.values(this.portfolioData.fmp));
        this.getAllCompanyPdfHistoricalPrice(this.generalHelperService.returnFormattedDateForFmp(new Date()));
      } else {
        this.formatterCurrencyExposureData(this.portfolioData.assets);
        this.returnTotalPortfolioValuationForCustodian(this.portfolioData);
        this.isAssetsPriceRequestFinished = true;
      }
      this.formatterMonthlyPerformanceData(Object.values(this.portfolioData.monthlyPerformance));
      // if (this.portfolioData?.assets?.assets) {
      this.initialFilteredAssets = this.portfolioData.assets.filter((element: any) => element.assetClassType.toLowerCase() !== 'portfolio');
      this.formatterAssetAllocationData(this.initialFilteredAssets);
      this.filteredAssets = this.portfolioData.assets;
      // } else if (this.portfolioData.assets) {
      //   this.filteredAssets = this.initialFilteredAssets;
        // this.formatterAssetAllocationData(this.initialFilteredAssets);
      // }
      const sameDates = [...new Set(Object.values(this.portfolioData.monthlyPerformance).map((x: any) => x.statementDate))];

      this.showDateWarning = Object.values(this.portfolioData.monthlyPerformance).length > 1 ? sameDates.length === Object.values(this.portfolioData.monthlyPerformance).length : false;
      this.portfolioDataYtd = this.portfolioData.ytd;

      const tickers = Object.keys(this.portfolioData.fmp).filter((x: any) => this.portfolioData.fmp[x].length);

      this.portfolioHttpService.getNews({tickers, from: this.initialDate, to: this.initialDate, limit: 6}).then(r => {
        this.news = r.data;
      });
    });
  }

  formattedPortfolioDataNew(portfolio: any): void {
    portfolio.assets = [];
    portfolio.price_history = null;
    portfolio.fmp = {};
    portfolio.ytd = 0;
    portfolio.monthlyPerformance = {};

    Object.keys(portfolio.files).forEach((key: string) => {
      if (portfolio.files[key].fmp) {
        portfolio.fmp = {...portfolio.fmp, ...this.generalHelperService.transformArrayToObject(portfolio.files[key].fmp)};
      }

      if (portfolio.files[key].price_history) {
        portfolio.price_history = {...portfolio.price_history, ...portfolio.files[key].price_history};
      }

      portfolio.ytd += portfolio.files[key].ytd?.ytdReturnPercentage ? portfolio.files[key].ytd.ytdReturnPercentage : 0;

      this.handleMonthlyPerformances(portfolio, key);

      if (portfolio.files[key].assets) {
        portfolio.files[key].assets.forEach((asset: any) => {
          asset.pdfName = key;

          if (asset.assetClassType.toLowerCase() === 'cash') {
            asset.name = asset.positionCurrency;
          }

          const foundAsset = portfolio.assets.find((element: any) => {
            return (asset.ticker ? element.ticker === asset.ticker : element.name === asset.name);
          });

          if (foundAsset) {
            foundAsset.pdf.push(asset);

            if (asset.assetClassType.toLowerCase() !== 'cash') {
              asset.valuationInOriginalCurrency = this.fixNumber(asset.valuationInOriginalCurrency) * this.currencyValues[asset.positionCurrency];

              if (Object.keys(portfolio.monthlyPerformance).includes(asset.pdfName) && Object.keys(portfolio.monthlyPerformance).length > 1) {
                  foundAsset.valuationInOriginalCurrency += this.handleValuationInOriginalCurrency(foundAsset, foundAsset.pdf);
                  foundAsset.hasMoreDiferentCustodians = ['equities', 'etf', 'equity'].includes(foundAsset.assetClassType.toLowerCase());
              }
            }

            foundAsset.pdfName = asset.pdfName;
            foundAsset.weight = asset.weight;
            foundAsset.quantity = foundAsset.valuationInOriginalCurrency;
            foundAsset.positionCurrency = asset.positionCurrency;
            foundAsset.fmpPriceOnPortfolioDate = this.handleFmpPriceOnPortfolioDate(portfolio, asset);
            foundAsset.valuationInOriginalCurrency += this.fixNumber(asset.valuationInOriginalCurrency);

            if (asset.assetClassType.toLowerCase() !== 'cash') {
              asset.positionCurrency = this.selectedBaseCurrency;
            }
          } else {
            const original = asset.valuationInOriginalCurrency;
            if (Object.keys(portfolio.monthlyPerformance).includes(asset.pdfName) && Object.keys(portfolio.monthlyPerformance).length > 1) {
              asset.valuationInOriginalCurrency += this.handleValuationInOriginalCurrency(asset, asset.pdf);
              asset.hasMoreDiferentCustodians = ['equities', 'etf', 'equity'].includes(asset.assetClassType.toLowerCase());
            }

            asset.quantity = original;
            asset.fmpPriceOnPortfolioDate = this.handleFmpPriceOnPortfolioDate(portfolio, asset);
            asset.valuationInOriginalCurrency = this.fixNumber(original) * this.currencyValues[asset.positionCurrency];

            if (asset.assetClassType.toLowerCase() !== 'cash') {
              asset.positionCurrency = this.selectedBaseCurrency;
            }

            portfolio.assets.push({...asset, pdf: [{...asset}]});
          }
        });
      }
    });

    portfolio.ytd = portfolio.ytd / Object.keys(portfolio.files).length;
    this.portfolioData = portfolio;
  }

  handleValuationInOriginalCurrency(foundAsset: any, pdf: Array<any>): number {
    let valuationInOriginalCurrency = 0;

    if (pdf !== undefined) {
      pdf.map(x => {
        valuationInOriginalCurrency += this.fixNumber(x.valuationInOriginalCurrency);

        if (foundAsset.positionCurrency !== this.selectedBaseCurrency) {
          valuationInOriginalCurrency = valuationInOriginalCurrency * this.currencyValues[foundAsset.positionCurrency];
        }
      });
    }

    return valuationInOriginalCurrency;
  }

  fixNumber(num: any): number {
    if (typeof num === 'number') {
      return num;
    } else {
      return parseFloat(num.replaceAll(',', '').replaceAll("'", ''));
    }
  }

  handleFmpPriceOnPortfolioDate(portfolio: any, asset: any): number {

    if (['equities', 'etf', 'equity'].includes(asset.assetClassType.toLowerCase())) {
      if (asset.ticker) {
        if (portfolio.fmp[asset.ticker].length) {
          if (portfolio.fmp[asset.ticker][0].currency === this.selectedBaseCurrency) {
            return portfolio.fmp[asset.ticker][0].price;
          }

          if (asset.positionCurrency !== this.selectedBaseCurrency) {
            return portfolio.fmp[asset.ticker][0].price * this.currencyValues[this.selectedBaseCurrency];
          }

          return portfolio.fmp[asset.ticker][0].price;
        }
      }
    }

    return 0;
  }

  handleMonthlyPerformances(portfolio: any, key: string): void {
    if (this.generalHelperService.isEmpty(portfolio.monthlyPerformance)) {
      portfolio.monthlyPerformance[key] = this.handleMonthlyPerformance(portfolio.files[key], key);
    } else {
      const foundPdfWithSameCustodianName: any = Object.values(portfolio.monthlyPerformance).find((element: any) => portfolio.files[key].statementDate.custodianInformation.name === element.custodian.name);
      if (foundPdfWithSameCustodianName) {
        if (new Date(portfolio.files[key].statementDate.statementDate) > new Date(foundPdfWithSameCustodianName.statementDate)) {
          delete portfolio.monthlyPerformance[foundPdfWithSameCustodianName.pdfName];
          portfolio.monthlyPerformance[key] = this.handleMonthlyPerformance(portfolio.files[key], key);
        }
      } else {
        portfolio.monthlyPerformance[key] = this.handleMonthlyPerformance(portfolio.files[key], key);
      }
    }
  }

  handleMonthlyPerformance(file: any, key: string): object {
    if (file.totalPortfolio?.baseCurrency === this.selectedBaseCurrency) {
      return {
        monthlyPerformance: file.monthlyPerformance,
        pdfName: key,
        statementDate: file.statementDate.statementDate,
        custodian: file.statementDate.custodianInformation,
        totalPortfolio: file.totalPortfolio
      };
    }

    return {
      monthlyPerformance: file.monthlyPerformance,
      pdfName: key,
      statementDate: file.statementDate.statementDate,
      custodian: file.statementDate.custodianInformation,
      totalPortfolio: {
        totalPortfolioValuation: file.totalPortfolio.totalPortfolioValuation * this.currencyValues[file.totalPortfolio?.baseCurrency],
        baseCurrency: file.totalPortfolio?.baseCurrency,
      }
    };
  }

  getAllCompanyPdfHistoricalPrice(date: string): void {
    this.isAssetsPriceRequestFinished = false;
    const ticker = Object.keys(this.portfolioData.fmp).filter((x: any) => this.portfolioData.fmp[x].length);

    // if (ticker.length) {
    //   this.portfolioHttpService.getAllPdfHistoricalPrice({
    //     ticker,
    //     from: date,
    //     to: date,
    //     timeframe: '1day'
    //   }).then(res => {
    //     this.allCompaniesFmpPrices = res.data;
    this.formatterCurrencyExposureData(this.portfolioData.assets);
    this.returnTotalPortfolioValuationForCustodian(this.portfolioData);
    //     this.isAssetsPriceRequestFinished = true;
    //   });
    // } else {
    this.isAssetsPriceRequestFinished = true;
    // }
  }

  formatterMonthlyPerformanceData(data: any): void {
    const chartData: any = {
      series: [],
      title: ChartTitles.monthlyPerformance,
      colors: ['#9E94E6', '#11BB8D'],
      tickAmount: 6
    };
    data.forEach((element: any) => {
      chartData.series.push({
        name: element.custodian.name + ' ' + formatDate(new Date(element.statementDate), 'dd.MM.yyyy.', 'en'),
        data: Object.keys(element.monthlyPerformance).map((dateKey) => {
          return {
            x: new Date(dateKey).getTime(),
            y: element.monthlyPerformance[dateKey]
          };
        })
      });
    });
    this.formattedMonthlyPerformanceData = chartData;
  }

  formatterCurrencyExposureData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const allPositionCurrencies = data.map((element: any) => {
      return element.positionCurrency;
    });
    chartData.labels = Array.from(new Set(allPositionCurrencies));
    chartData.labels.forEach((label: any) => {
      chartData.series.push(data.reduce((accumulator: number, currentValue: any) => {
        if (currentValue.positionCurrency === label) {
        //   if ((currentValue.assetClassType === 'equity' || currentValue.assetClassType === 'etf' || currentValue.assetClassType === 'equities') && currentValue.ticker && currentValue.quantity) {
        //     return accumulator + (typeof currentValue.quantity === 'string' ? Number(currentValue.quantity.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.quantity) *
        //       (this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf']?.length ? this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf'][0].close : 0);
        //   } else {

            return accumulator + (currentValue.valuationInOriginalCurrency ? (typeof currentValue.valuationInOriginalCurrency === 'string' ? Number(currentValue.valuationInOriginalCurrency.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.valuationInOriginalCurrency) : 0);
          // }
        }
        return accumulator;
      }, 0));
    });
    this.returnTotalPortfolioValuation(chartData);
  }

  formatterAllocationBySectorData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const sectors = data
      .map((e: any) => {
        if (e.length) {
          return e[0].sector.toLowerCase();
        }
      })
      .filter((elem: any) => elem !== undefined && elem !== '');
    chartData.labels = Array.from(new Set(sectors));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    sectors.forEach((element: string) => {
      seriesData[element.toLowerCase()] += 1;
    });
    chartData.labels = this.generalHelperService.capitalizeLabels(chartData.labels);
    chartData.series = Object.values(seriesData);
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedAllocationBySectorData = chartData;
  }

  formatterCountryExposureData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const countries = data
      .map((e: any) => {
        if (e.length) {
          return e[0].country;
        }
      }).filter((elem: any) => elem !== undefined && elem !== '' && elem !== null);
    chartData.labels = Array.from(new Set(countries));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    countries.forEach((element: string) => {
      seriesData[element.toLowerCase()] += 1;
    });
    chartData.series = Object.values(seriesData);
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedCountryExposureData = chartData;
  }


  formatterAssetAllocationData(data: any): void {
    const chartData: any = {series: [], labels: []};
    const assetsTypes = data.map((value: any) => {
      return value.assetClassType.toString().charAt(0).toUpperCase() + value.assetClassType.slice(1);
    });
    chartData.labels = Array.from(new Set(assetsTypes));
    const seriesData = chartData.labels.reduce((acc: any, key: string) => {
      acc[key.toLowerCase()] = 0;
      return acc;
    }, {});
    assetsTypes.forEach((element: string) => {
      (seriesData.hasOwnProperty(element.toLowerCase()))
        ? seriesData[element.toLowerCase()] = seriesData[element.toLowerCase()] + 1
        : seriesData.others = seriesData.others + 1;
    });
    chartData.series = Object.values(seriesData);
    this.filterItems = [...chartData.labels];
    const seriesSum = this.generalHelperService.calculateSumOfArrayValues(chartData.series);
    chartData.series = chartData.series.map((element: number) => {
      return (element / seriesSum) * 100;
    });
    this.formattedAssetAllocationData = chartData;
  }

  getFmpData(): void {
    this.portfolioHttpService.getFmpData({ticker: [this.activeTicker]}).then(res => {
      this.fmpCompanyData = res.data;
    });
  }

  onDateChange(date: any): void {
    this.selectedDate = date;
    this.onCurrencyChange(date, this.selectedBaseCurrency);
  }

  onCurrencyChange(date: any, baseCurrency: string): void {
    this.isAssetsPriceRequestFinished = false;
    this.selectedBaseCurrency = baseCurrency;
    const formattedDateFmp = this.generalHelperService.returnFormattedDateForFmp(date);
    this.isInitialDate = date.toString() === new Date(new Date().setHours(0, 0, 0, 0)).toString();
    this.generalHelperService.checkIsToday(formattedDateFmp) ? this.getLatestCurrencyExchangeRates() : this.getHistoryCurrencyExchangeRates({date: formattedDateFmp, from: this.selectedBaseCurrency});
    // this.getAllCompanyPdfHistoricalPrice(formattedDateFmp);
  }

  closeUploadStatementModalAndRedirect(): void {
    this.isUploadModalShow = false;
    this.router.navigate([PAGES_AND_PATHS.portfolio.pagePath]);
  }

  handleOpenSingleAssets(assetType: string, ticker: string, portfolio: any): void {
    if ((assetType.toLowerCase() === 'equity' || assetType.toLowerCase() === 'equities' || assetType.toLowerCase() === 'eft') && ticker) {
      this.openSingleAssets(ticker, portfolio);
    }
  }

  openSingleAssets(ticker: string, portfolio: any): void {
      this.activeTicker = ticker;
      this.activeCompanyPortfolioData = portfolio;
      this.getFmpData();
      this.isPortfolioAssetsShow = true;
  }

  getLatestCurrencyExchangeRates(): void {
    this.portfolioHttpService.getLatestExchangeRates({from: this.selectedBaseCurrency}).then(res => {
      this.currencyValues = res.data.latest_currency.data;
      this.getSinglePortfolioData();
    });
  }

  getHistoryCurrencyExchangeRates(urlParams: any): void {
    this.portfolioHttpService.getHistoryExchangeRates({...urlParams}).then(res => {
      this.currencyValues = res.data.latest_currency.data;
      this.getSinglePortfolioData();
    });
  }

  returnTotalPortfolioValuation(data: any): void {
    let totalPortfolio = 0;
    const seriesInActiveCurrency = data.labels.map((label: string, index: number) => {
      return (data.series[index] / (label === 'XAU' ? 1 : this.currencyValues[data.labels[index]]));
    });
    totalPortfolio = this.generalHelperService.calculateSumOfArrayValues(seriesInActiveCurrency);
    data.series = seriesInActiveCurrency.map((element: number) => {
      return (element / totalPortfolio) * 100;
    });
    this.formattedCurrencyExposureData = data;
  }

  returnTotalPortfolioValuationForCustodian(data: any): void {
    let totalPortfolio = 0;
    Object.keys(data.monthlyPerformance).forEach(key => {
      const chartData: any = {series: [], labels: []};
      const allPositionCurrencies = data.assets.map((element: any) => {
        return element.positionCurrency;
      });
      chartData.labels = Array.from(new Set(allPositionCurrencies));
      chartData.labels.forEach((label: any) => {
        chartData.series.push(data.assets.reduce((accumulator: number, currentValue: any) => {
          if (currentValue.positionCurrency === label && currentValue.pdfName === key) {
            // if ((currentValue.assetClassType === 'equity' || currentValue.assetClassType === 'etf' || currentValue.assetClassType === 'equities') && currentValue.ticker && currentValue.quantity) {
            //   return accumulator + (typeof currentValue.quantity === 'string' ? Number(currentValue.quantity.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.quantity) *
            //     (this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf']?.length ? this.allCompaniesFmpPrices[currentValue.ticker.toLowerCase()]['price_from_pdf'][0].close : 0);
            // } else {
              return accumulator + (currentValue.valuationInOriginalCurrency ? (typeof currentValue.valuationInOriginalCurrency === 'string' ? Number(currentValue.valuationInOriginalCurrency.replace(/[\s-$%,'A-Za-z]+/g, '')) : currentValue.valuationInOriginalCurrency) : 0);
            // }
          }
          return accumulator;
        }, 0));
      });
      const seriesInActiveCurrency = chartData.labels.map((label: string, index: number) => {
        return (chartData.series[index] / (label === 'XAU' ? 1 : this.currencyValues[chartData.labels[index]]));
      });
      this.portfolioData.monthlyPerformance[key].custodianTotal = this.generalHelperService.calculateSumOfArrayValues(seriesInActiveCurrency);
      totalPortfolio += this.portfolioData.monthlyPerformance[key].totalPortfolio.totalPortfolioValuation;
    });
    this.totalPortfoliosValuation = totalPortfolio;
  }

  onFilterSelected(selectedFilters: any[]): void {
    const lowerCaseSelectedFilters = selectedFilters.map(element => element.toLowerCase());
    (selectedFilters.length)
      ? this.filteredAssets = this.initialFilteredAssets.filter(element => lowerCaseSelectedFilters.includes(element.assetClassType.toLowerCase()))
      : this.filteredAssets = this.initialFilteredAssets;
  }

  buildForm(): void {
    this.portfolioForm = this.formBuilder.group({
      title: [this.singlePortfolioData.name, [Validators.required]],
    });
  }

  submitPortfolioForm(): void {
    if (this.portfolioForm.valid) {
      this.portfolioHttpService.updateTitle({id: this.singlePortfolioData.id}, this.portfolioForm.value).then((res: any) => {
        this.toastrService.success('Title changed');
        this.editPortfolioName = false;
        this.singlePortfolioData.name = this.portfolioForm.value.title;
        history.state.data.name = this.singlePortfolioData.name;
        history.replaceState({ ...history.state }, '', window.location.href);
      }).catch(({error}: {error: { errors: { [key: string]: string } }}) => {
        Object.values(error.errors).forEach(error => this.toastrService.error(error));
      });
    }
  }

  downloadCsv(): void {
    return this.download(true);
  }
  downloadExcel(): void {
    return this.download(false);
  }

  download(csv = false): void {
    this.isAssetsPriceRequestFinished = false;

    this.portfolioHttpService.download({
      csv,
      totalPortfoliosValuation: this.totalPortfoliosValuation,
      selectedBaseCurrency: this.selectedBaseCurrency,
      ytd: this.portfolioDataYtd,
      monthlyPerformance: this.portfolioData.monthlyPerformance,
      filteredAssets: this.filteredAssets,
    }).then(res => {
      const blob = new Blob([res.data], (csv ? {type: 'text/csv'} : {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = this.singlePortfolioData.name + (csv ? '.csv' : '.xlsx');
      document.body.appendChild(a);
      a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
      this.isAssetsPriceRequestFinished = true;
    });
  }
}
